<script lang="ts">
    import { instagram, reddit, twitter, youtube } from "@timephy/tui-icons-svelte"
    import { Icon } from "svelte-awesome"

    import { tiktok } from "@timephy/tui-icons-svelte"

    /// Allow hide links to GroupTube's pages, when being served as a fallback page.
    export let hideInternalLinks = false
</script>

<footer class="flex flex-col items-center justify-center gap-5 p-6 text-sm">
    <!-- !! Social Media -->
    <div class="flex items-center justify-center gap-8">
        <a
            href="https://www.youtube.com/channel/UCvhWN_fK5vLjJh0l1aqwzlQ"
            aria-label="YouTube"
            target="_blank"
            rel="noopener noreferrer"
            class="text-step-500 hover:text-step-600 active:text-step-700"
        >
            <Icon data={youtube} class="h-[18px] w-[18px]" />
        </a>
        <a
            href="https://www.instagram.com/grouptube_app"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
            class="text-step-500 hover:text-step-600 active:text-step-700"
        >
            <Icon data={instagram} class="h-[18px] w-[18px]" />
        </a>
        <a
            href="https://www.tiktok.com/@grouptube_app"
            aria-label="TikTok"
            target="_blank"
            rel="noopener noreferrer"
            class="text-step-500 hover:text-step-600 active:text-step-700"
        >
            <Icon data={tiktok} class="h-[18px] w-[18px]" />
        </a>
        <a
            href="https://twitter.com/grouptube_app"
            aria-label="Twitter"
            target="_blank"
            rel="noopener noreferrer"
            class="text-step-500 hover:text-step-600 active:text-step-700"
        >
            <Icon data={twitter} class="h-[18px] w-[18px]" />
        </a>
        <a
            href="https://www.reddit.com/r/grouptube"
            aria-label="Reddit"
            target="_blank"
            rel="noopener noreferrer"
            class="text-step-500 hover:text-step-600 active:text-step-700"
        >
            <Icon data={reddit} class="h-[18px] w-[18px]" />
        </a>
    </div>

    {#if !hideInternalLinks}
        <!-- !! Links -->
        <div class="flex gap-5 sm:gap-7">
            <a href="/legal/cookies" class="link">Cookies</a>
            <a href="/legal/imprint" class="link">Imprint</a>
            <a href="/legal/terms" class="link">Terms</a>
            <a href="/legal/privacy" class="link">Privacy</a>
            <a href="/legal/licenses" class="link">Licenses</a>
        </div>
    {/if}

    <!-- !! Copyright -->
    <p class="text-center text-xs text-step-500">
        Copyright © {new Date().getFullYear()} GroupTube. All rights reserved.
    </p>
</footer>
